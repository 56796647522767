import ApplicationController from './application_controller';

export default class extends ApplicationController {

  // static targets = ['amount']

  connect() {
    super.connect()
    this.bidId = parseInt(this.data.get("dataItemId"))
    console.log(this.bidId)
    console.log('connectedHere')
  }

  beforePurchase(element, reflex, noop, reflexId) {
    console.log(element);
    console.log(reflex);
    console.log(reflexId);
    console.log('before-reflex');
    document.getElementById('auction-desk-purchase').disabled = true;
    document.getElementById('auction-desk-purchase').classList.add("hide");
    document.getElementById('auction-desk-cancel-purchase').classList.add("hide");
    document.getElementById('auction-desk-purchase-processing').classList.remove("hide");
  }
  // beforeReflex() {
  //   console.log('before-reflex');
  //   document.body.classList.add('wait')
  //   document.getElementById('auction-desk-purchase').disabled = true;
  //   document.getElementById('auction-desk-purchase').classList.add("hide");
  //   document.getElementById('auction-desk-cancel-purchase').classList.add("hide");
  //   document.getElementById('auction-desk-purchase-processing').classList.remove("hide");
  // }



  afterReflex() {
    console.log('after-reflex');
    document.body.classList.remove('wait');
    $('#bids').DataTable().rows().deselect();
  }
  assign(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    console.log(this)
    this.stimulate("AuctionDesk#assign", this.bidId)

  }

  unassign(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    console.log(event)
    this.stimulate("AuctionDesk#assign", event.target)

  }

  update_assignment(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    console.log(event)
    this.stimulate("AuctionDesk#update_assignment", event.target)

  }

  // increment(event) {
  //   this.minimumBid = parseFloat(this.data.get("nextBid"));
  //   this.minimumBidIncrement = parseFloat(this.data.get("minimumBidIncrement"));
  //   this.currentBid += this.minimumBidIncrement
  //   this.amountTarget.value = this.currentBid.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  //   event.preventDefault();
  //   event.stopImmediatePropagation();
  // }

  // decrement(event) {
  //   this.minimumBid = parseFloat(this.data.get("nextBid"));
  //   this.minimumBidIncrement = parseFloat(this.data.get("minimumBidIncrement"));
  //   this.currentBid -= this.minimumBidIncrement
  //   if (this.currentBid < this.minimumBid) {
  //     this.currentBid = this.minimumBid;
  //   }
  //   this.amountTarget.value = this.currentBid.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  //   event.preventDefault();
  //   event.stopImmediatePropagation();
  // }


}
