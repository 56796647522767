import ApplicationController from './application_controller.js'

export default class extends ApplicationController {

  static targets = ['amount']

  connect() {
    this.minimumBid = parseFloat(this.data.get("nextBid"))
    this.minimumBidIncrement = parseFloat(this.data.get("minimumBidIncrement"));
    this.currentBid = parseFloat(this.amountTarget.value);
    this.amountTarget.value = this.currentBid.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });

  }


  increment(event) {
    this.minimumBid = parseFloat(this.data.get("nextBid"));
    this.minimumBidIncrement = parseFloat(this.data.get("minimumBidIncrement"));
    this.currentBid += this.minimumBidIncrement
    this.amountTarget.value = this.currentBid.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  decrement(event) {
    this.minimumBid = parseFloat(this.data.get("nextBid"));
    this.minimumBidIncrement = parseFloat(this.data.get("minimumBidIncrement"));
    this.currentBid -= this.minimumBidIncrement
    if (this.currentBid < this.minimumBid) {
      this.currentBid = this.minimumBid;
    }
    this.amountTarget.value = this.currentBid.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    event.preventDefault();
    event.stopImmediatePropagation();
  }


}
