/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import 'stylesheets/application'
// import "core-js/stable";
// import "regenerator-runtime/runtime";

console.log('Hello World from Webpacker 22')



// require("@rails/ujs").start()
// import "jquery"
// import "jquery-ui"
// import "bootstrap"
// import "@hotwired/turbo-rails"
require("@rails/activestorage").start()

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import alpinejs and its necessary rails adaptation
// import 'alpine-turbo-drive-adapter'
// import Alpine from 'alpinejs';

// window.Alpine = Alpine

// Alpine.start()

console.log('Hello World from alpinejs')

require("channels")
require("jquery")
// require("bootstrap")
// require("@hotwired/turbo-rails")
// require('webpack-jquery-ui')


// import { Turbo } from "@hotwired/turbo-rails"
// window.Turbo = Turbo
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/OtherComponent.vue -> <other-component></other-component>
 * Eg. ./UI/ButtonComponent.vue -> <button-component></button-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => {
//   const component = key.split('/').pop().split('.')[0]
//   console.log(key);
//   // With Lazy Loading
//   Vue.component(component, () =>
//     import (`${key}`))

//   // Or without Lazy Loading
//   // Vue.component(component, files(key).default)
// })


// import Vue from 'vue/dist/vue.esm'
// import StripeExternalAccountForm from '../stripe-external-account-form.vue'

// document.addEventListener('DOMContentLoaded', () => {
//   console.log('loading component');
//   const app = new Vue({
//     el: '#stripe-external-account-form',
//     data: {
//       message: "Can you say hello?"
//     },
//     components: { StripeExternalAccountForm }
//   })
//   console.log(app)
//   console.log('hello-2')
// })
// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => {
//   const component = key.split('/').pop().split('.')[0]

//   // With Lazy Loading
//   Vue.component(component, () =>
//     import (`${key}`))

//   // Or without Lazy Loading
//   Vue.component(component, files(key).default)
// })


// Install Airbrake JS Notifier per https://airbrake.io/docs/installing-airbrake/installing-airbrake-js-in-a-rails-app/
import { Notifier } from '@airbrake/browser';
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import controllers from '../controllers/application_controller'

// const airbrake = new Notifier({
//   projectId: 93597,
//   projectKey: '3daa36ffc5995395fae06c1d0c549a7d'
// });

// airbrake.addFilter((notice) => {
//   notice.context.environment = process.env.RAILS_ENV;
//   return notice;
// });

// try {
//   throw new Error('hello from airbrake-js');
// } catch (err) {
//   airbrake.notify(err).then((notice) => {
//     if (notice.id) {
//       console.log('notice id:', notice.id);
//     } else {
//       console.log('notify failed:', notice.error);
//     }
//   });
// }
// StimulusReflex.initialize(application, { consumer, controller, isolate: true })
StimulusReflex.debug = process.env.RAILS_ENV === 'development'
console.log('activated')
import "controllers"

import { Application } from "stimulus"
import Timeago from "stimulus-timeago"
import "config"
import "channels"

const application = Application.start()
application.register("timeago", Timeago)
