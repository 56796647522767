import ApplicationController from './application_controller'

import StimulusReflex from "stimulus_reflex";
import consumer from "../channels/consumer";
import CableReady from "cable_ready";
// import { values } from 'core-js/core/array';


/* This is the custom StimulusReflex controller for the Bid Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
   */
  // connect() {
  //   StimulusReflex.register(this);

  //   consumer.subscriptions.create({ channel: "ListChannel", list_id: this.data.get("id") }, {
  //     received: (data) => {
  //       if (data.cableReady) CableReady.perform(data.operations);
  //     },
  //   });

  //   Sortable.create(this.tasksTarget, {
  //     handle: ".incomplete",
  //     onEnd: (event) => this.reorder(event),
  //   });
  // }
  connect() {
    // super.connect();
    StimulusReflex.register(this);
    console.log('alan now');
    console.log(this.data);
    console.log(this.data.get('opportunity_id'));
    console.log(this.data.get('id'));
    consumer.subscriptions.create({ channel: "AuctionItemChannel", auction_item_id: this.data.get("id") }, {
      connected: () => {
        console.log("connected to auction_item");

      },
      received: (data) => {
        console.log('received auction item data')
        console.log(data);
        if (data.cableReady) CableReady.perform(data.operations);
        console.log(data)
        console.log('alan-item');
        if (data.cableReady) console.log(data);
        if (data.cableReady) console.log(data.operations);
        if (data.cableReady) {
          CableReady.perform(data.operations);
          console.log(data)
          console.log('auction item')
          var selector = data.operations[0].selector;
          console.log(selector);
          console.log(selector.substring(0, 13));
          // Update my bid items
          if (selector.substring(0, 13) == "#my_bid_items_list") {

            console.log(`${selector} .bid_form input[name="current_bidder_id"]`);
            // Who plced the bid
            var bidUserId = parseInt($(`${selector} .bid_form input[name="current_bidder_id"]`)[0].value);
            var winningUserIds = $(`${selector} .bid_form input[name="winning_user_ids"]`)[0].value.split(" ").map(x => +x);
            // Current user placed the bid
            if (winningUserIds.includes(window.givsum_user_id)) {
              console.log('same user');
              var bidItemIndex = myBids.findIndex((obj => obj.store_item_id == auctionItemId));
              // Show div for current user and keep other user div hidden
              $(`${selector} .bidder_is_current_winner`).removeClass('hide');
            } else {
              // Another user placed the bid
              // Pull current user's last bid
              // Check for am I still a winning bid
              var auctionItemId = parseInt($(`${selector} .bid_form input[name="auction_item_id"]`)[0].value)
              // var bidItemIndex = myBids.findIndex((obj => obj.store_item_id == auctionItemId));
              var myBidsMap = JSON.parse(localStorage.getItem('userBidsMap'));
              var myLastBid = parseFloat(myBidsMap[auctionItemId].amount).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
              console.log('replace value');
              console.log(`${selector} .bidder_is_not_current_winner .bid-amount .value`);
              $($(`${selector} .bidder_is_not_current_winner .bid-amount .value`)[0]).text(myLastBid)
              // Show div for current user and keep other user div hidden
              $(`${selector} .bidder_is_not_current_winner`).removeClass('hide');
            }

          };
          // Update Item page
          if (selector.substring(0, 20) == "#bid_form_store_item" && $(`${selector} .bid_form`).length > 0) {
            console.log(`${selector} .bid_form input[name="current_bidder_id"]`);
            // Who placed the bid
            var bidUserId = parseInt($(`${selector} .bid_form input[name="current_bidder_id"]`)[0].value);
            var winningUserIds = $(`${selector} .bid_form input[name="winning_user_ids"]`)[0].value.split(" ").map(x => +x);
            // Current user placed the bid
            console.log('winning_user_ids');
            console.log(winningUserIds);
            console.log(window.givsum_user_id)

            if (winningUserIds.includes(window.givsum_user_id)) {
              console.log('same user');
              // Another user placed the bid
              // Pull current user's last bid
              var auctionItemId = parseInt($(`${selector} .bid_form input[name="auction_item_id"]`)[0].value);
              var opportunityId = $(`${selector} .bid_form input[name="opportunity_id"]`)[0].value;
              var auctionBidAmount = $(`${selector} .bid_form input[name="bid_amount"]`)[0].value;
              var winningUserIds = $(`${selector} .bid_form input[name="winning_user_ids"]`)[0].value.split(" ").map(x => +x);


              var myBidsMap = JSON.parse(localStorage.getItem('userBidsMap'));
              var myBids = JSON.parse(localStorage.getItem('userBids'));
              var hasOutbids = myBids.filter(function (e) { return e.winning_bid === false; }).length > 0;
              if (document.getElementById("outbid_indicator")) {
                if (hasOutbids) {
                  document.getElementById("outbid_indicator").classList.remove("hide");
                } else {
                  document.getElementById("outbid_indicator").classList.add("hide");
                }
              }
              var bidItemIndex = myBids.findIndex((obj => obj.store_item_id == auctionItemId));
              // Check for am I still a winning bid
              // Check in the form

              if (winningUserIds.includes(window.givsum_user_id)) {
                //  TODO - figure out one solution for this
                // THis works for mobile IOS
                document.getElementById('place_bid_button').disabled = true
                // This works for desktop
                $('#bid_form input.btn.btn-primary').map((_index, element) => { element.disabled = true; });
                // $('#bid_form input.btn.btn-primary').map((_index, element) => { element.disabled = true; });
              }
              // if (myBidsMap[auctionItemId]) {
              //   myBidsMap[auctionItemId].amount = auctionBidAmount;
              // } else {
              //   myBidsMap[auctionItemId] = {
              //     store_item_id: auctionItemId,
              //     user_id: window.givsum_user_id,
              //     amount: auctionBidAmount,
              //     opportunity_id: opportunityId
              //   }
              // }
              $(`${selector} .bidder_is_current_winner`).removeClass('hide');
            } else {
              // Another user placed the bid
              // Pull current user's last bid
              // Check for am I still a winning bid
              var myBidsMap = JSON.parse(localStorage.getItem('userBidsMap'));
              var myBids = JSON.parse(localStorage.getItem('userBids'));
              var hasOutbids = myBids.filter(function (e) { return e.winning_bid === false; }).length > 0;
              if (document.getElementById("outbid_indicator")) {
                if (hasOutbids) {
                  document.getElementById("outbid_indicator").classList.remove("hide");
                } else {
                  document.getElementById("outbid_indicator").classList.add("hide");
                }
              }
              var auctionItemId = parseInt($(`${selector} .bid_form input[name="auction_item_id"]`)[0].value)
              var bidItemIndex = myBids.findIndex((obj => obj.store_item_id == auctionItemId));
              var winningUserIds = $(`${selector} .bid_form input[name="winning_user_ids"]`)[0].value.split(" ").map(x => +x);
              // Check for am I still a winning bid
              // Check in the form

              console.log('winning_user_ids');
              console.log(winningUserIds);
              console.log(window.givsum_user_id)

              // $('#bid_form input.btn.btn-primary').map((_index, element) => { element.removeAttribute('disabled')  });
              // $('btn.place_bid_button').map((_index, element) => { element.disabled = false; });
              // TODO find solution for this
              // This works for mobile IOS
              document.getElementById('place_bid_button').disabled = false
              // This works for desktop
              $('#bid_form input.btn.btn-primary').map((_index, element) => { element.disabled = false; });
              // if (winningUserIds.includes(window.givsum_user_id)) {
              //   $('#bid_form input.btn.btn-primary').map((_index, element) => { element.disabled = true; });
              // } else {
              //   $('#bid_form input.btn.btn-primary').map((_index, element) => { element.disabled = false; });
              // }
              // Handle the case of the first bid
              // Is this the right order

              if (myBidsMap[auctionItemId]) {
                var myLastBid = parseFloat(myBidsMap[auctionItemId].amount).toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                });
                // $($(`${selector} .bidder_is_not_current_winner .bid-amount .value`)[0]).text(myLastBid)
                $(`${selector} .bidder_is_not_current_winner`).removeClass('hide');
              };
            };
          }
          console.log('buy_now_user_ids');
          var buyNowUserIds = $(` .bid_form input[name="buy_now_user_ids"]`)[0].value.split(" ").map(x => +x);
          console.log(buy_now_user_ids);
          if (buyNowUserIds.includes(window.givsum_user_id)) {
            var buyNowButtons = document.getElementsByClassName('buy_now_button');
            console.log('buyNowButtons')
            console.log(buyNowButtons)
            for (var i = 0; i < buyNowButtons.length; i++) {
              buyNowButtons[i].classList.add(('d-none'))
            }

          }

        }
      },
    });
    // add your code here, if applicable
  }

  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="bid" to your markup alongside
   * data-reflex="Bid#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->Bid#dance" data-controller="bid">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Bid#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  // Assuming you create a "Bid#dance" action in your Reflex class
  // you'll be able to use the following lifecycle methods:

  // beforeDance(element, reflex, noop, reflexId) {
  //  element.innerText = 'Putting dance shoes on...'
  // }

  // danceSuccess(element, reflex, noop, reflexId) {
  //   element.innerText = '\nDanced like no one was watching! Was someone watching?'
  // }

  // danceError(element, reflex, error, reflexId) {
  //   console.error('danceError', error);
  //   element.innerText = "\nCouldn\'t dance!"
  // }

  // afterDance(element, reflex, noop, reflexId) {
  //   element.innerText = '\nWhatever that was, it\'s over now.'
  // }

  // finalizeDance(element, reflex, noop, reflexId) {
  //   element.innerText = '\nNow, the cleanup can begin!'
  // }
}
