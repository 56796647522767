// Auction  Controller

import ApplicationController from './application_controller';

import StimulusReflex from "stimulus_reflex";
import consumer from "../channels/consumer";
import CableReady from "cable_ready";


/* This is the custom StimulusReflex controller for the Bid Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
   */
  // connect() {
  //   StimulusReflex.register(this);

  //   consumer.subscriptions.create({ channel: "ListChannel", list_id: this.data.get("id") }, {
  //     received: (data) => {
  //       if (data.cableReady) CableReady.perform(data.operations);
  //     },
  //   });

  //   Sortable.create(this.tasksTarget, {
  //     handle: ".incomplete",
  //     onEnd: (event) => this.reorder(event),
  //   });
  // }
  static values = { user: String }


  connect() {
    // super.connect();
    StimulusReflex.register(this);
    console.log('user');
    console.log(this.id);

    // fetch(this.userValue).then({
    //   // console.log(`User found: ${this.userValue}`);
    //   userId: this.userValue;
    // }
    // );
    console.log(`user id: ${this.data.get("userValue")}`);
    // console.log(`user id: ${this.data.get("user_id")}`);
    // consumer.subscriptions.create({ channel: "AuctionChannel", opportunity_id: this.data.get("id") }, {
    const userId = this.data.get("userValue");
    consumer.subscriptions.create({ channel: "UserChannel", user_id: userId }, {

      connected: () => {
        console.log("connected to user")
      },
      received: (data) => {
        console.log('received user data');
        // console.log(data);
        // window.givsum_data = data;
        if (data.cableReady) CableReady.perform(data.operations);
        // if (data.cableReady) console.log(data);
        // if (data.cableReady) console.log(data.operations);
        //
        // var myBids = JSON.parse(localStorage.getItem('userBids'));
        console.log('alan')
        var myBids =  JSON.parse(localStorage.getItem('userBids'));
        var hasOutbids = myBids.filter(function(e) { return e.winning_bid === false; }).length > 0;
        if (hasOutbids && document.getElementById("outbid_indicator")) {
          document.getElementById("outbid_indicator").classList.remove("hide");
        } else {
          document.getElementById("outbid_indicator").classList.add("hide");
        }
        if (myBids) {
          myBids.forEach(function (bid) {
            var itemId = bid.store_item_id;
            var selector = `#items_list_store_item_${itemId} .auction-item`;
            document.querySelector(selector).classList.add("current-user-has-bid");
          });
        }
      },
    });
    // add your code here, if applicable
  }

}

/* Reflex specific lifecycle methods.
 *
 * For every method defined in your Reflex class, a matching set of lifecycle methods become available
 * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
 * need them.
 *
 * Important:
 * Make sure to add data-controller="bid" to your markup alongside
 * data-reflex="Bid#dance" for the lifecycle methods to fire properly.
 *
 * Example:
 *
 *   <a href="#" data-reflex="click->Bid#dance" data-controller="bid">Dance!</a>
 *
 * Arguments:
 *
 *   element - the element that triggered the reflex
 *             may be different than the Stimulus controller's this.element
 *
 *   reflex - the name of the reflex e.g. "Bid#dance"
 *
 *   error/noop - the error message (for reflexError), otherwise null
 *
 *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
 */

// Assuming you create a "Bid#dance" action in your Reflex class
// you'll be able to use the following lifecycle methods:

// beforeDance(element, reflex, noop, reflexId) {
//  element.innerText = 'Putting dance shoes on...'
// }

// danceSuccess(element, reflex, noop, reflexId) {
//   element.innerText = '\nDanced like no one was watching! Was someone watching?'
// }

// danceError(element, reflex, error, reflexId) {
//   console.error('danceError', error);
//   element.innerText = "\nCouldn\'t dance!"
// }

// afterDance(element, reflex, noop, reflexId) {
//   element.innerText = '\nWhatever that was, it\'s over now.'
// }

// finalizeDance(element, reflex, noop, reflexId) {
//   element.innerText = '\nNow, the cleanup can begin!'
// }
//}
