import { Controller } from "stimulus"
import StimulusReflex from "stimulus_reflex";

export default class extends Controller {
  static targets = ["form", "card", "errors"]

  connect() {
    console.log('Am I working')
    StimulusReflex.register(this)
    this.stripe = Stripe(this.data.get("public-key"));
    console.log('Alan3');
    const hiddenUserInput = document.createElement("input");
    hiddenUserInput.setAttribute("type", "hidden");
    hiddenUserInput.setAttribute("name", "user");
    hiddenUserInput.setAttribute("value", this.data.get("user"));
    this.formTarget.appendChild(hiddenUserInput)
    let buttons = document.getElementsByClassName("stripe-add-card-button")
    buttons[buttons.length -1].disabled = true;
    const elements = this.stripe.elements();
    // const style = JSON.parse(this.data.get("style"))
    // const style = {
    //   base: {
    //     color: '#32325d',
    //     fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    //     fontSmoothing: 'antialiased',
    //     fontSize: '16px',
    //     '::placeholder': {
    //       color: '#aab7c4'
    //     }
    //   },
    //   invalid: {
    //     color: '#fa755a',
    //     iconColor: '#fa755a'
    //   }
    // };

    const style = {

      base: {
        color: '#333333',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        fontWeight: 'bold',
        '::placeholder': {
          color: '#333333'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };


    this.card = elements.create("card", { style: style });
    this.card.mount(this.cardTarget);
    // Handle real-time validation errors from the card Element.
    let controller = this;
    this.card.addEventListener('change', function(event) {
      console.log('testing');
      const displayError = controller.errorsTarget;
      let buttons = document.getElementsByClassName("stripe-add-card-button")
      if (event.error) {
        displayError.textContent = event.error.message;
        buttons[buttons.length -1].disabled = true;
      } else if (event.complete) {
        displayError.textContent = "";
        buttons[buttons.length -1].disabled = false;
        console.log('complete');
        console.log('change');
      } else {
        displayError.textContent = "";
        buttons[buttons.length -1].disabled = true;
      }
    });
  }

  change(event) {
    console.log('change2');
    console.log(event)
    if (event.complete) {
      console.log('true');
      event.complete == true;
    }
    if (event.error) {
      this.errorsTarget.textContent = event.error.message
      let buttons = document.getElementsByClassName("stripe-add-card-button")
      buttons[buttons.length -1].disabled = true;
    } else {
      this.errorsTarget.textContent = ""
    }
  }

  stripeTokenHandler(token) {
    const hiddenInput = document.createElement("input")

    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", "stripeToken")
    hiddenInput.setAttribute("value", token.id)
    this.formTarget.appendChild(hiddenInput)

    console.log('before reflex');
    this.card.update({ disabled: true });


    this.stimulate('Stripe#create_payment_method').then(() => {
      // this.element.reset()
      console.log('we are back')
        // initMap();
        // pageSetup();
      document.body.classList.remove('wait');
      console.log('after reflex');


      // optional: set focus on the freshly cleared input
      // this.focusTarget.focus()
    })

  }

  submit(event) {
    event.preventDefault()
    let buttons = document.getElementsByClassName("stripe-add-card-button")
    buttons[buttons.length -1].textContent = "Adding Card..."
    buttons[buttons.length -1].disabled = true
    this.stripe.createToken(this.card).then((result) => {
      if (result.error) {
        console.log(result);
        this.errorsTarget.textContent = result.error.message
        buttons[buttons.length -1].disabled = true;
      } else {
        this.stripeTokenHandler(result.token)
      }
    })

  }

  usersubmit(event) {
    event.preventDefault()

    this.stripe.createToken(this.card).then((result) => {
      if (result.error) {
        console.log(result);
        this.errorsTarget.textContent = result.error.message
        let buttons = document.getElementsByClassName("stripe-add-card-button")
        buttons[buttons.length -1].disabled = true;
      } else {
        this.stripeTokenHandler(result.token)
      }
    })

  }
}
