// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from "stimulus_reflex";
import consumer from "../channels/consumer";
import controller from '../controllers/application_controller'
import NestedForm from "stimulus-rails-nested-form"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
console.log(definitionsFromContext(context))
StimulusReflex.initialize(application, { consumer });

application.register("nested-form", NestedForm)

if (process.env.RAILS_ENV === 'development') {
  import('radiolabel').then(Radiolabel =>
    application.register('radiolabel', Radiolabel.default)
  )
}
