import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Bid Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
   */

  beforeReflex(element, reflex, noop, reflexId) {
    console.log(element);
    console.log(element.dataset);
    console.log($(element));

    console.log(reflex);
    var $element = $(element);

    // Pull current user's last bid
    var auctionItemId = parseInt($element.find('input[name="auction_item_id"]')[0].value);
    var opportunityId = parseInt($element.find('input[name="opportunity_id"]')[0].value);
    var auctionBidAmount = $element.find('input[name="amount"]')[0].value;
    console
    console.log('this is active')
    console.log('bid amount')
    var myBidsMap =  JSON.parse(localStorage.getItem('userBidsMap'));
    if (myBidsMap[auctionItemId]) {
      myBidsMap[auctionItemId].amount = auctionBidAmount;
    } else {
      myBidsMap[auctionItemId] = {
        store_item_id: auctionItemId,
        amount: auctionBidAmount,
        opportunity_id: opportunityId
      }
    }
    localStorage.setItem('userBidsMap', JSON.stringify(myBidsMap));
    var numberOfItemBids = Object.keys(myBidsMap).length;
    $('#my-bids-tab').text(`My Bids (${numberOfItemBids})`);
  }


  connect() {
    super.connect()
    console.log('connected here bid controller')

  }


  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="bid" to your markup alongside
   * data-reflex="Bid#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->Bid#dance" data-controller="bid">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Bid#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  // Assuming you create a "Bid#dance" action in your Reflex class
  // you'll be able to use the following lifecycle methods:

  // beforeDance(element, reflex, noop, reflexId) {
  //  element.innerText = 'Putting dance shoes on...'
  // }

  // danceSuccess(element, reflex, noop, reflexId) {
  //   element.innerText = '\nDanced like no one was watching! Was someone watching?'
  // }

  // danceError(element, reflex, error, reflexId) {
  //   console.error('danceError', error);
  //   element.innerText = "\nCouldn\'t dance!"
  // }

  // afterDance(element, reflex, noop, reflexId) {
  //   element.innerText = '\nWhatever that was, it\'s over now.'
  // }

  // finalizeDance(element, reflex, noop, reflexId) {
  //   element.innerText = '\nNow, the cleanup can begin!'
  // }

  increment(e) {
    var amount = this.amount;
    amount.replace(/[$,]+/g, "");
    amount += this.minimumBidIncrement
    this.amount = amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }

  decrement(e) {
    var amount = this.amount;
    amount.replace(/[$,]+/g, "");
    amount -= this.minimumBidIncrement
    amount -= this.minimumBidIncrement
    if (amount < this.minimumBid) {
      amount = this.minimumBid
    }
    this.amount = amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });


  }



  submit(e) {
    console.log('preventDefault');
    e.preventDefault();
    e.target.disabled = true;
    document.body.classList.add('wait');
    console.log('before reflex');


    this.stimulate('BidReflex#bid').then(() => {
      // this.element.reset()
      console.log('we are back')
        // initMap();
        // pageSetup();
      document.body.classList.remove('wait');
      console.log('after reflex');


      // optional: set focus on the freshly cleared input
      // this.focusTarget.focus()
    })
  }

  cancelBid(e) {
    console.log('cancelling bid');
    e.preventDefault();
    e.target.disabled = true;
    // document.body.classList.add('wait');
    console.log('before reflex');


    this.stimulate('BidReflex#cancel_bid').then(() => {
      // this.element.reset()
      console.log('we are back')
        // initMap();
        // pageSetup();
      document.body.classList.remove('wait');
      console.log('after reflex');


      // optional: set focus on the freshly cleared input
      // this.focusTarget.focus()
    })
  }
}
