// // app/javascript/controllers/email_controller.js
// import { Controller } from "stimulus";
// import StimulusReflex from "stimulus_reflex";

// export default class extends Controller {
//   static targets = ["groupSelect"];

//   connect() {
//     StimulusReflex.register(this);
//     console.log("Email Controller Connected");
//   }

//   previewRecipients(event) {
//     console.log("previewRecipients");
//     event.preventDefault();
//     this.stimulate("EmailReflex#preview_recipients", this.groupSelectTarget);
//   }
// }


import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Bid Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  /*
   * Regular Stimulus lifecycle methods
   * Learn more at: https://stimulusjs.org/reference/lifecycle-callbacks
   *
   * If you intend to use this controller as a regular stimulus controller as well,
   * make sure any Stimulus lifecycle methods overridden in ApplicationController call super.
   *
   * Important:
   * By default, StimulusReflex overrides the -connect- method so make sure you
   * call super if you intend to do anything else when this controller connects.
   */

  beforeReflex(element, reflex, noop, reflexId) {

    console.log('here I am')
    // console.log(element);
    // console.log(element.dataset);
    // console.log($(element));

    // console.log(reflex);
    // var $element = $(element);

    // // Pull current user's last bid
    // var auctionItemId = parseInt($element.find('input[name="auction_item_id"]')[0].value);
    // var opportunityId = parseInt($element.find('input[name="opportunity_id"]')[0].value);
    // var auctionBidAmount = $element.find('input[name="amount"]')[0].value;
    // console
    // console.log('this is active')
    // console.log('bid amount')
    // var myBidsMap =  JSON.parse(localStorage.getItem('userBidsMap'));
    // if (myBidsMap[auctionItemId]) {
    //   myBidsMap[auctionItemId].amount = auctionBidAmount;
    // } else {
    //   myBidsMap[auctionItemId] = {
    //     store_item_id: auctionItemId,
    //     amount: auctionBidAmount,
    //     opportunity_id: opportunityId
    //   }
    // }
    // localStorage.setItem('userBidsMap', JSON.stringify(myBidsMap));
    // var numberOfItemBids = Object.keys(myBidsMap).length;
    // $('#my-bids-tab').text(`My Bids (${numberOfItemBids})`);
  }

  static targets = [
    "volunteersGroup",
    "attendeesGroup",
    "donorsGroup",
    "allAuctionBiddersGroup",
    "winningAuctionBiddersGroup",
    "selectAllButton",
    "groupIds",
    "autoGeneratedGroups",
    "opportunityIds",
    "template",
    "subject",
    "submitSaveDraft",
    "submitSendNow",
    "previewButton",
    "attachment",
    "deleteField"

  ];


  connect() {
    StimulusReflex.register(this);

    window.addEventListener("beforeunload", this.confirmPageLeave.bind(this));
    this.setupAttachmentListeners();
    // Add click event listeners to the saveDraft and deliverNow buttons
    // this.saveDraftTarget.addEventListener("click", this.disableConfirmation.bind(this));
    // this.deliverNowTarget.addEventListener("click", this.disableConfirmation.bind(this));

    // Flag to track whether confirmation should be disabled
    this.preventLeaveAlert = false;

    $('textarea').on({
      input: function () {
        var totalHeight = $(this).prop('scrollHeight') - parseInt($(this).css('padding-top')) - parseInt($(this).css('padding-bottom'));
        $(this).css({ 'height': totalHeight + 5 });
      }
    });
    this.selectingAll = false;
    console.log("Email Controller Connected");
    const selectedTemplate = this.templateTarget.value;
    console.log("selectedTemplate")
    console.log(selectedTemplate)

    // Call the method to update the UI based on the template
    this.updateTemplateUI(selectedTemplate);

    // Also trigger the previewRecipients action
    this.previewRecipients();
  }

  disableConfirmation() {
    // Set flag to true when Save Draft or Deliver Now buttons are clicked
    this.preventLeaveAlert = true;
  }

  confirmPageLeave(event) {
    // If preventLeaveAlert is false, show a confirmation alert when the user tries to leave
    if (!this.preventLeaveAlert) {
      const confirmationMessage = "Are you sure you want to leave the page? Unsaved changes will be lost.";
      event.preventDefault();
      event.returnValue = confirmationMessage; // Standard for most browsers
      return confirmationMessage; // Some browsers require this return for the message to show
    }
  }

  disconnect() {
    // Clean up event listeners when the controller is disconnected
    window.removeEventListener("beforeunload", this.confirmPageLeave.bind(this));
  }


  generatePreview(event) {
    event.preventDefault();

    const subjectLine = encodeURIComponent(document.getElementById("org_email_subject_line").value);
    const noteField = document.querySelector('.fr-element.fr-view').innerHTML;

    let message = "false";
    let previewNote = noteField;
    if (noteField.length >= 400) {
      message = "true";
      previewNote = noteField.slice(0, 400) + "... (Note is too long to preview. You send send a draft to see the whole message)";
    }
    const noteFieldEncoded = encodeURIComponent(previewNote);

    const template = document.getElementById("org_email_template").value;
    const opportunityId = document.querySelector("#org_email_opportunity_ids").value;
    const attendees = document.getElementById("org_email_attendees").checked ? "true" : "false";
    const showLocation = document.getElementById("org_email_show_location").checked;
    const showDate = document.getElementById("org_email_show_date").checked;
    const showOrganizationName = document.getElementById("org_email_show_organization_name").checked;
    const showPrimaryImage = document.getElementById("org_email_show_primary_image").checked;
    const showSubtitle = document.getElementById("org_email_show_subtitle").checked;
    const showTitle = document.getElementById("org_email_show_title").checked;
    // const baseUrl = this.previewButtonTarget.href;
    // const baseUrl = "https://www.givsum.me:3000/charities/test-organization/organization_email_preview"
    const baseUrl = event.currentTarget.dataset.url;
    console.log(baseUrl);
    // const info = `?subject_line=${subjectLine}&note_field=${noteFieldEncoded}&template=${template}&opportunity_id=${opportunityId}&attendees=${attendees}&message=${message}`;
    const info = `?subject_line=${subjectLine}&note_field=${noteFieldEncoded}&template=${template}&opportunity_id=${opportunityId}&attendees=${attendees}&message=${message}&show_location=${showLocation}&show_date=${showDate}&show_organization_name=${showOrganizationName}&show_primary_image=${showPrimaryImage}&show_subtitle=${showSubtitle}&show_title=${showTitle}`;

    this.previewButtonTarget.href = baseUrl + info;

    console.log(`Preview URL: ${this.previewButtonTarget.href}`);



    // window.open(this.previewButtonTarget.href, '_blank');
  }

  sendTestEmail(event) {
    event.preventDefault();

    // Get the URL from the button's data-url attribute
    const url = event.currentTarget.dataset.url;

    // Get the values from the form fields
    const subjectLine = document.getElementById("org_email_subject_line").value;
    const noteField = document.querySelector('div.fr-wrapper > div').innerHTML;
    const template = document.getElementById("org_email_template").value;
    const opportunityId = document.querySelector("#org_email_opportunity_ids").value;
    const showLocation = document.getElementById("org_email_show_location").checked;
    const showDate = document.getElementById("org_email_show_date").checked;
    const showOrganizationName = document.getElementById("org_email_show_organization_name").checked;
    const showPrimaryImage = document.getElementById("org_email_show_primary_image").checked;
    const showSubtitle = document.getElementById("org_email_show_subtitle").checked;
    const showTitle = document.getElementById("org_email_show_title").checked;

    let surveyId = null;


    // Prepare the email parameters
    const emailParams = {
      subject_line: subjectLine,
      note_field: noteField,
      template: template,
      opportunity_id: opportunityId,
      show_location: showLocation,
      show_date: showDate,
      show_organization_name: showOrganizationName,
      show_primary_image: showPrimaryImage,
      show_subtitle: showSubtitle,
      show_title: showTitle

    };


    console.log('email params', emailParams);
    console.log('url', url)
    // Send the AJAX request using fetch
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify(emailParams)
    })
      .then(response => response.json())
      .then(data => {
        // Handle success response
        console.log('successful send')
        alert(data.message);
      })
      .catch(error => {
        console.log('error')
        console.log()
        // Handle error response
        alert("There seems to be an error trying to send a test email.");
      });
  }

  checkSubject() {
    const subjectValue = this.subjectTarget.value;

    if (subjectValue.length > 0) {
      this.submitSaveDraftTarget.disabled = false;
      this.submitSendNowTarget.disabled = false;
    } else {
      this.submitSaveDraftTarget.disabled = true;
      this.submitSendNowTarget.disabled = true;
    }
  }

  confirmSaveDraftSubmit(event) {
    this.disableConfirmation();
    // Prevent the form from submitting immediately
    event.preventDefault();

    // Show confirmation dialog
    const confirmed = confirm("Are you sure you want to save this as a draft email?");

    // If confirmed, submit the form
    if (confirmed) {
      // Get the URL from the button's data-url attribute (or hardcode if needed)
      const draftUrl = event.currentTarget.dataset.url;
      console.log('draftUrl');
      console.log(draftUrl);

      // Find the form that the submit button belongs to
      const form = this.submitSaveDraftTarget.closest('form');

      // Update the form's action attribute to the new URL
      form.action = draftUrl;

      // Submit the form
      form.submit();
    }
  }


  confirmDeliverNowSubmit(event) {
    this.disableConfirmation();
    // Prevent the form from submitting immediately
    event.preventDefault();

    // Show confirmation dialog
    const confirmed = confirm("Are you sure you want to send this email now?");

    // If confirmed, submit the form
    if (confirmed) {

      // Get the URL from the button's data-url attribute (or hardcode if needed)
      const sendUrl = event.currentTarget.dataset.url;

      // Find the form that the submit button belongs to
      const form = this.submitSendNowTarget.closest('form');

      // Update the form's action attribute to the new URL
      form.action = sendUrl;

      // Submit the form
      form.submit();
    }
  }

  // This method is triggered when the template is changed
  onTemplateChange(event) {
    const selectedTemplate = event.target.value;

    // Call the method to update the UI based on the template
    this.updateTemplateUI(selectedTemplate);

    // Also trigger the previewRecipients action
    this.previewRecipients();
  }

  // Logic to show/hide sections based on the template selected
  updateTemplateUI(template) {
    if (template === "notification") {
      console.log('updating template')
      this.showElement(".notification");
      this.showElement(".notification-desc");
      this.hideElement(".general");
      this.hideElement(".general-desc");
      this.hideElement(".survey");
      this.hideElement(".survey-desc");
    } else if (template === "general") {
      this.showElement(".general");
      this.showElement(".general-desc");
      this.hideElement(".notification");
      this.hideElement(".notification-desc");
      this.hideElement(".survey");
      this.hideElement(".survey-desc");
    } else if (template === "survey") {
      this.showElement(".survey");
      this.showElement(".survey-desc");
      this.hideElement(".general");
      this.hideElement(".general-desc");
      this.showElement(".notification");
      this.hideElement(".notification-desc");

      // Call any extra logic needed when "survey" is selected
      this.generalSurveyCheck();
    }
  }

  // Utility methods to show/hide elements
  showElement(selector) {
    document.querySelectorAll(selector).forEach((element) => {
      element.style.display = "block";
    });
  }

  hideElement(selector) {
    document.querySelectorAll(selector).forEach((element) => {
      element.style.display = "none";
    });
  }

  // Placeholder for additional logic when the "survey" template is selected
  generalSurveyCheck() {
    // Additional custom logic here if needed
  }
  toggleSelectAll() {
    this.selectingAll = !this.selectingAll;

    // Select or deselect all options
    const groupIdsOptions = this.groupIdsTarget.options;
    for (let i = 0; i < groupIdsOptions.length; i++) {
      groupIdsOptions[i].selected = this.selectingAll;
    }
    const autoGeneratedGroupOptions = this.autoGeneratedGroupsTarget.options;
    for (let i = 0; i < autoGeneratedGroupOptions.length; i++) {
      autoGeneratedGroupOptions[i].selected = this.selectingAll;
    }

    // Trigger the previewRecipients method after selecting/deselecting all
    this.previewRecipients();
    this.updateButtonText();
  }

  previewRecipients(event) {
    console.log("previewRecipients");
    // event.preventDefault();
    // this.stimulate("EmailReflex#preview_recipients", null);
    console.log(event);
    document.getElementById('spinner-overlay').classList.remove('d-none');

    this.stimulate('EmailReflex#preview_recipients').then(() => {
      // this.element.reset()
      console.log('we are back')
      // initMap();
      // pageSetup();
      console.log('after reflex 2');
      // Hide spinner
      document.getElementById('spinner-overlay').classList.add('d-none');
      // optional: set focus on the freshly cleared input
      // this.focusTarget.focus()
    })

    // Update button text dynamically


  }
  updateButtonText() {
    this.selectAllButtonTarget.innerText = this.selectingAll ? "Deselect All" : "Select All";
  }

  // Method triggered when individual selection changes
  handleChange(event) {
    this.previewRecipients(); // Trigger the preview whenever individual selection changes
  }


  setupAttachmentListeners() {
    console.log('attachmentTargets')
    this.attachmentTargets.forEach((attachmentElement) => {
      console.log('attachmentTargets inner loop')
      console.log(attachmentElement)
      const deleteLink = attachmentElement.querySelector(".remove-attachment");
      console.log(deleteLink)
      if (!deleteLink) return;
      deleteLink.addEventListener("click", (e) => {
        e.preventDefault();
        const attachmentId = deleteLink.dataset.attachmentId;
        console.log(attachmentId)
        this.markAttachmentForDeletion(attachmentId, attachmentElement);
      });
    });
  }

  markAttachmentForDeletion(attachmentId, attachmentElement) {
    // Find the corresponding hidden field for this attachment and mark it for deletion
    console.log('marking attachment for deletion')
    console.log(attachmentId)
    console.log(attachmentElement)
    const deleteField = document.getElementById(`attachment_${attachmentId}_delete`);
    deleteField.value = attachmentId;
    attachmentElement.style.display = "none"; // Hide the attachment in the UI
  }

  /* Reflex specific lifecycle methods.
   *
   * For every method defined in your Reflex class, a matching set of lifecycle methods become available
   * in this javascript controller. These are optional, so feel free to delete these stubs if you don't
   * need them.
   *
   * Important:
   * Make sure to add data-controller="bid" to your markup alongside
   * data-reflex="Bid#dance" for the lifecycle methods to fire properly.
   *
   * Example:
   *
   *   <a href="#" data-reflex="click->Bid#dance" data-controller="bid">Dance!</a>
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Bid#dance"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  // Assuming you create a "Bid#dance" action in your Reflex class
  // you'll be able to use the following lifecycle methods:

  // beforeDance(element, reflex, noop, reflexId) {
  //  element.innerText = 'Putting dance shoes on...'
  // }

  // danceSuccess(element, reflex, noop, reflexId) {
  //   element.innerText = '\nDanced like no one was watching! Was someone watching?'
  // }

  // danceError(element, reflex, error, reflexId) {
  //   console.error('danceError', error);
  //   element.innerText = "\nCouldn\'t dance!"
  // }

  // afterDance(element, reflex, noop, reflexId) {
  //   element.innerText = '\nWhatever that was, it\'s over now.'
  // }

  // finalizeDance(element, reflex, noop, reflexId) {
  //   element.innerText = '\nNow, the cleanup can begin!'
  // }




  // cancelBid(e) {
  //   console.log('cancelling bid');
  //   e.preventDefault();
  //   e.target.disabled = true;
  //   // document.body.classList.add('wait');
  //   console.log('before reflex');


  //   this.stimulate('CancelBidReflex#cancel_bid').then(() => {
  //     // this.element.reset()
  //     console.log('we are back')
  //       // initMap();
  //       // pageSetup();
  //     document.body.classList.remove('wait');
  //     console.log('after reflex');


  //     // optional: set focus on the freshly cleared input
  //     // this.focusTarget.focus()
  //   })
  // }
}
